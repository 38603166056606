import Heading from "Components/Heading";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BiSearch, BiExport, BiLike } from "react-icons/bi";
import Footer from "Components/Footer";
import DataTable from "Components/CustomTable";
import TicketAPIs from "../../APIs/ticket";
import Loader from "Components/Loader";
import SearchForm from "Components/SearchForm";
import { toast } from "react-toastify";
import SampleDataTable from "Components/DataTables";
import { formatDate } from "Helper/Converters";
import * as XLSX from "xlsx-js-style";
import {
  exportFileToExcel,
  wrapLastTextInBracketsWithStrong,
} from "Utils/utils";

const SearchTicket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectionChange = (selectedIds) => {
    setSelectedIds(selectedIds);
  };

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticketNo}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          className={`badge ${row.original?.cancelDate && "custom-badge"} ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticketNo}
          {row.original?.cancelDate && <span className="cancel-btn"></span>}
        </a>
      ),
    },
    {
      accessor: "location",
      Header: "Location",
      Cell: ({ row }) => {
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {row.original.digAddress} {"\n"}
            {row.original.digCity}
            {row.original.company_name} {"\n"}
            {row.original.contractor_name} {"\n"}
            {row.original.region} {"\n"}
            <strong>{`(${row.original.priority})`}</strong>
          </div>
        );
      },
    },
    { accessor: "entered", Header: "Entered" },
    {
      accessor: "assigntoo",
      Header: "Assigned To",
      Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
    },
    {
      accessor: "assign",
      Header: "Assigned Date",
    },
    {
      accessor: "reassinged",
      Header: "Reassigned To",
      Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    },
    { accessor: "reassingndate", Header: "Reassigned Date" },
    { accessor: "dueDate", Header: "Due" },
    { accessor: "completedDate", Header: "Completed" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
    {
      accessor: "isAssignPerTicket",
      Header: "Assign Per Ticket",
      Cell: ({ row }) => (
        <p>
          {row.original.isAssignPerTicket ? (
            <BiLike color="green" size={30} />
          ) : (
            <BiLike color="red" size={30} />
          )}
        </p>
      ),
    },
  ];

  /**
   * Submit Handler for office Clear
   */

  const formatDateForExcel = () => {
    let formattedData = [
      [
        {
          v: "Ticket #",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Address",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Contractor",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Type of Ticket",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Entered Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Assigned Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Assigned To",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Due Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Completed Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Station Code",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Segment",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Office Clear",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Locate City",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Caller Name",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Type of Work",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Per-Ticket",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
      ],
    ];
    searchedData.forEach((item) => {
      let reason = "";
      // if (item.attemptedrescheduledate || item.renegotiateddate || item.completeddate) {
      //   const days = parseInt((new Date(item.attemptedrescheduledate || item.renegotiateddate || item.completeddate).getTime() - new Date(item.compliancedate).getTime()) / 86400000)
      //   reason = item.renegotiateddate ? `Rescheduled ${days} days(s) after entered date regulation` : `Completed ${days} day(s) after regulation`
      // }
      formattedData.push([
        { v: item.ticketNo },
        {
          v: `${item.digAddress}\n${item.region}\n${item.priority}`,
        },
        { v: `${item.company_name} ${item.contractor_name} ${item.region}` },
        // { v: item.companyName },
        { v: item.priority },
        { v: item.entered ? formatDate(item.entered) : "" },
        { v: item.assign },
        {
          v: item.assigntoo
            ? `${item.assigntoo.firstName} ${item.assigntoo.lastName}`
            : "",
        },
        { v: item.due ? formatDate(item.dueDate) : "" },
        { v: item.completedDate ? formatDate(item.completedDate) : "" },
        { v: item.code?.map((item) => item.name).join(", ") },
        { v: item.segmet },
        { v: item.isOfficeClear ? "Yes" : "No" },
        { v: item.region || "" },
        { v: item.contractor_name || "" },
        { v: item.ticketType },
        { v: item.isAssignPerTicket ? "Yes" : "No" },
      ]);
    });

    return formattedData;
  };

  const exportToExcel = () => {
    if (!(searchedData.length > 0)) {
      toast.error("No data found yet!");
      return;
    }
    const data = formatDateForExcel();
    const fileName = "Searched_Tickets";
    exportFileToExcel(data, fileName);
  };

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <section>
        <Heading text={"Query/Search Ticket"} btn />

        <Card>
          <SearchForm
            setSearchedData={setSearchedData}
            setIsLoading={setIsLoading}
            loadDataOnMount={false}
            // filters={{ isOfficeClear: false }}
          />
          {/* <Row className="mb-5">
            <Col md={12}>
              <Button
                className="me-3"
                onClick={() => {
                  officeClearOrClose("close");
                }}
              >
                <BiSearch />
                Close Tickets
              </Button>
              <Button
                onClick={() => {
                  officeClearOrClose("office_clear");
                }}
              >
                <BiExport />
                Office Clear
              </Button>
            </Col>
          </Row> */}
          <Col md={12} className="d-flex justify-content-end mb-3">
            <Button onClick={exportToExcel}>
              <BiExport />
              Export
            </Button>
          </Col>
          <SampleDataTable
            columns={columns}
            data={searchedData}
            onSelectionChange={handleSelectionChange}
          />
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default SearchTicket;
