// sseService.js
import { ENDPOINT } from "config/constants";

class SSEService {
  constructor() {
    this.eventSource = null;
    this.listeners = {};
    this.clientId = null;
    this.retryTime = 1000; // Initial retry time
    this.maxRetryTime = 30000; // Max retry time (30 sec)
    this.reconnectTimeout = null;
    this.countdownInterval = null;
    this.countdown = 0;
  }

  // 🔥 Start SSE Connection
  connect(clientId) {
    if (this.eventSource) return; // Prevent duplicate connections

    this.clientId = clientId;
    const timestamp = new Date().getTime();
    console.log(`🔄 Connecting SSE for client ${clientId}...`);

    this.eventSource = new EventSource(
      `${ENDPOINT.sse.connect}/${clientId}?t=${timestamp}`
    );

    // Attach event listeners
    this.eventSource.addEventListener("message", (event) =>
      this.notifyListeners("message", event)
    );
    this.eventSource.addEventListener("notifications", (event) =>
      this.notifyListeners("notifications", event)
    );
    this.eventSource.addEventListener("connection", (event) => {
      this.notifyListeners("connection", event);
      this.resetRetry();
    });

    // Error handling & reconnection
    this.eventSource.onerror = (error) => {
      console.warn("⚠️ SSE Connection Error:", error);
      this.handleDisconnection();
    };
  }

  // 🔥 Notify all registered listeners
  notifyListeners(eventType, event) {
    if (this.listeners[eventType]) {
      this.listeners[eventType].forEach((callback) => callback(event));
    }
  }

  // 🔥 Subscribe to SSE Events
  on(eventType, callback) {
    if (!this.listeners[eventType]) {
      this.listeners[eventType] = [];
    }
    this.listeners[eventType].push(callback);
  }

  // 🔥 Unsubscribe from SSE Events
  off(eventType, callback) {
    if (this.listeners[eventType]) {
      this.listeners[eventType] = this.listeners[eventType].filter(
        (cb) => cb !== callback
      );
    }
  }

  // 🔥 Handle Disconnection & Retry Logic
  handleDisconnection() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }

    this.startRetryCountdown();

    this.reconnectTimeout = setTimeout(() => {
      console.log(`🔄 Retrying SSE connection in ${this.retryTime / 1000}s...`);
      this.retryTime = Math.min(this.retryTime * 2, this.maxRetryTime);
      this.connect(this.clientId);
    }, this.retryTime);
  }

  // 🔥 Start countdown before retrying
  startRetryCountdown() {
    this.countdown = this.retryTime / 1000;
    this.notifyListeners("retrying", { data: this.countdown });

    clearInterval(this.countdownInterval);
    this.countdownInterval = setInterval(() => {
      this.countdown--;
      this.notifyListeners("retrying", { data: this.countdown });

      if (this.countdown <= 0) {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }

  // 🔥 Reset retry time when connection is restored
  resetRetry() {
    this.retryTime = 1000;
    clearTimeout(this.reconnectTimeout);
    clearInterval(this.countdownInterval);
  }

  // 🔥 Close Connection
  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
    clearTimeout(this.reconnectTimeout);
    clearInterval(this.countdownInterval);
  }
}

// Export a **singleton instance** for global use
export default new SSEService();
