import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Tab, Tabs, Row } from "react-bootstrap";
import TicketAPIs from "../../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
import { formatDate } from "Helper/Converters";
import MapBox from "Components/MapBox";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { MdOutlineRefresh } from "react-icons/md";
import AuthAPIs from "../../../APIs/auth";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";
import { priorityColors, wrapLastTextInBracketsWithStrong } from "Utils/utils";

const UserTicketDetail = ({ show, onHide, userId, selectedTab = "tab1" }) => {
  const [key, setKey] = useState(selectedTab);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [unAssignedTicket, setUnAssignedTicket] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [endDate, setEndDate] = useState(new Date());

  const handleSelectionChange = (selectedIds) => {
    setSelectedIds(selectedIds);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const getUserTickets = async () => {
    setIsLoading(true);

    const res = await TicketAPIs.searchTicket({
      assignTo: userId,
      reAssignTo: userId,
      completedDate: false,
    });

    if (res) {
      setSearchedData(res.data?.data || []);

      const sortedTicket = res.data.data?.map((item, index) => {
        return {
          id: index,
          ticketId: item.id,
          ticketNo: item.ticketNo,
          label: `${
            item?.digAddress != null || item?.digAddress !== undefined
              ? item?.digAddress
              : ""
          } (${item?.ticketType})`,
          lat: parseFloat(item.lat), // Convert to number
          lng: parseFloat(item.lng), // Convert to number
          color: priorityColors[item.priority],
        };
      });
      setUnAssignedTicket(sortedTicket);
    }
    setIsLoading(false);
  };

  const getUsers = async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [];
    if (users) {
      users.data?.data?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.firstName} ${item?.lastName}`,
        };
        if (item?.id !== userId) customArr.push(data);
      });
      customArr.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([{ value: "", label: "Select" }, ...customArr]);
    }
  };

  useEffect(() => {
    setSearchedData([]);
    setUnAssignedTicket([]);
    getUserTickets();
    getUsers();
  }, [userId]);

  useEffect(() => {
    setKey(selectedTab);
  }, [selectedTab]);

  const reAssignTicket = async () => {
    if (!selectedUser) {
      toast.error("Please Select User to Assign");
      return;
    }
    if (!selectedIds[0]) {
      toast.error("No Tickets Selected.");
      return;
    }
    setIsLoading(true);
    const res = await TicketAPIs.assignReassignTicket({
      ticketId: selectedIds,
      userId: selectedUser,
      type: "re_assign",
      reAssignDate: moment(endDate).format("YYYY-MM-DD"),
    });
    if (res) {
      toast.success("Ticket Re-assigned Successfully");
      setSelectedUser(null);
      setSelectedIds([]);
      getUserTickets();
    }
    setIsLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          User Ticket Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="user-ticket-detail-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="tab1" title="Map">
            {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
            {searchedData.length > 0 ? (
              <MapBox
                locations={unAssignedTicket}
                enablePolygon={false}
                enableTicketDetail={true}
                mapContainerStyle={{
                  height: "560px",
                  width: "100%",
                  borderRadius: "10px",
                }}
              />
            ) : null}
          </Tab>
          <Tab eventKey="tab2" title="Tickets">
            <Form>
              <Row>
                <div className="headingBox">
                  <h6>Reassign Tickets</h6>
                </div>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Reassign From</Form.Label>
                    <Select
                      options={users}
                      styles={customStyles}
                      defaultValue={users[0]}
                      onChange={(selectedOption) => {
                        setSelectedUser(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Reassigned Date</Form.Label>
                    <div className="datepicker_box">
                      <CustomDatePicker
                        selected={endDate}
                        onSelect={(date) => setEndDate(date)}
                        dateFormat="MM/dd/yyyy"
                      />
                      <span>
                        <MdDateRange />
                      </span>
                    </div>
                  </Form.Group>
                </Col>

                <Col className="mb-4 column" style={{ maxWidth: "160px" }}>
                  <Button
                    style={{ minHeight: "44px", marginTop: "30px" }}
                    onClick={() => {
                      reAssignTicket();
                    }}
                  >
                    <MdOutlineRefresh />
                    Reassign
                  </Button>
                </Col>
              </Row>
            </Form>
            <SampleDataTable
              columns={[
                {
                  accessor: "ticket",
                  Header: "Ticket No",
                  Cell: ({ row }) => (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
                        // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
                        const windowName = `popUpWindow_${row.original.ticketNo}`;
                        const windowSize = "width=800,height=600";
                        window.open(url, windowName, windowSize);
                      }}
                      href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
                      rel="noopener noreferrer"
                      className={`badge ${
                        row.original.ticketType == "EMERGENCY" ||
                        row.original.priority == "EMERGENCY"
                          ? "emergency"
                          : row.original.ticketType == "MULTIPLE" ||
                            row.original.priority == "MULTIPLE"
                          ? "multiple"
                          : row.original.ticketType == "PRIORITY" ||
                            row.original.priority == "PRIORITY"
                          ? "priority"
                          : row.original.ticketType == "PROJECT WORK" ||
                            row.original.priority == "PROJECT WORK"
                          ? "projectWork"
                          : row.original.ticketType == "STANDARD" ||
                            row.original.priority == "STANDARD"
                          ? "standard"
                          : "default"
                      }`}
                    >
                      {row.original.ticketNo}
                    </a>
                  ),
                },
                {
                  accessor: "location",
                  Header: "Location",
                  Cell: ({ row }) => {
                    return (
                      <div style={{ whiteSpace: "pre-line" }}>
                        {row.original.company_name} {"\n"}
                        {row.original.contractor_name} {"\n"}
                        {row.original.digAddress} {"\n"}
                        {row.original.digCity}
                        {row.original.region} {"\n"}
                        <strong>{`(${row.original.priority})`}</strong>
                      </div>
                    );
                  },
                },
                { accessor: "entered", Header: "Entered" },
                {
                  accessor: "assigntoo",
                  Header: "Assigned To",
                  Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
                },
                {
                  accessor: "assign",
                  Header: "Assigned Date",
                },
                {
                  accessor: "reassinged",
                  Header: "Reassigned To",
                  Cell: ({ row }) => (
                    <p>{row.original.reassigned?.firstName}</p>
                  ),
                },
                { accessor: "reassingndate", Header: "Reassigned Date" },
                {
                  accessor: "due",
                  Header: "Due",
                  Cell: ({ row }) => (
                    <p>
                      {row.original.rescheduleDate
                        ? row.original.rescheduleDate
                        : row.original.due}
                    </p>
                  ),
                },
                {
                  accessor: "code",
                  Header: "Station Code",
                  Cell: ({ row }) => (
                    <p>
                      {Array.isArray(row.original?.code)
                        ? row.original?.code.map((item) => item.name).join(", ")
                        : row.original?.code || ""}
                    </p>
                  ),
                },
                { accessor: "segmet", Header: "Segment" },
              ]}
              data={searchedData}
              onSelectionChange={handleSelectionChange}
              isLoading={isLoading}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserTicketDetail;
