import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

const MapBox = React.memo(
  ({
    locations,
    onSelectedLocations,
    enablePolygon,
    isMarkerClickable = true,
    enableTicketDetail,
    isDetailComponent = false,
    mapContainerStyle,
  }) => {
    const memoizedLocations = useMemo(() => locations, [locations]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [map, setMap] = useState(null);
    const drawingManagerRef = useRef(null);
    const markersRef = useRef([]);
    const [mapLoaded, setMapLoaded] = useState(false);

    const navigate = useNavigate();
    const libraries = ["drawing", "geometry"];

    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: "AIzaSyDli8luQtXdHmh8iU566z8QQJqVQhBLSis",
      libraries,
    });

    // Utility function to remove all markers from the map
    const clearMarkers = () => {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];
    };

    const handleMapLoad = useCallback((mapInstance) => {
      setMap(mapInstance);

      locations.forEach((location, index) => {
        //TODO: Change to AdvanceMarkerView
        const marker = new window.google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: mapInstance,
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 6,
            fillColor: location.color,
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: "white",
          },
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: location.label,
        });

        marker.addListener("mouseover", () => {
          infoWindow.open({
            anchor: marker,
            map: mapInstance,
            shouldFocus: false,
          });
        });

        // Optional: Close the InfoWindow on mouseout
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });

        if (isMarkerClickable)
          marker.addListener("click", () => handleMarkerClick(location));
      });

      setMapLoaded(true);

      // Ensure enablePolygon is true and the drawing library is loaded
      // if (enablePolygon && isLoaded) {
      //   initializeDrawingManager(mapInstance);
      // }
    }, []);

    const initializeDrawingManager = (mapInstance) => {
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
        },
      });

      drawingManager.setMap(mapInstance);

      window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        (event) => {
          if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const polygon = event.overlay;
            polygon.setOptions({ draggable: false });

            const markersInsidePolygon = locations.filter((location) => {
              // Create LatLng object correctly
              const point = new window.google.maps.LatLng(
                location.lat,
                location.lng
              );
              return window.google.maps.geometry.poly.containsLocation(
                point,
                polygon
              );
            });

            setSelectedLocations(markersInsidePolygon);
            onSelectedLocations(markersInsidePolygon);

            drawingManager.setDrawingMode(null);
          }
        }
      );

      drawingManagerRef.current = drawingManager;
    };

    const handleMarkerClick = useCallback(
      (location) => {
        if (enableTicketDetail) {
          const uniqueWindowName = `popup_${
            location.ticketNo
          }_${new Date().getTime()}`;
          window.open(
            `/dashboard/ticket-detail/${location.ticketNo}`,
            uniqueWindowName,
            "width=800,height=600"
          );
          return;
        }
        if (isMarkerClickable) {
          onSelectedLocations([location]);
        }
      },
      [
        enableTicketDetail,
        isMarkerClickable,
        selectedLocations,
        onSelectedLocations,
      ]
    );

    const handleMarkerClose = useCallback(() => {
      setSelectedMarker(null);
    }, []);

    // const containerStyle = {
    //   width: "100%",
    //   height: "400px",
    // };

    useEffect(() => {
      if (!mapLoaded || !isLoaded) return;

      // Clear existing markers
      clearMarkers();
      locations.forEach((location, index) => {
        const marker = new window.google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: map,
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 6,
            fillColor: location.color,
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: "white",
          },
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: location.label,
        });

        marker.addListener("mouseover", () => {
          infoWindow.open({
            anchor: marker,
            map: map,
            shouldFocus: false,
          });
        });

        // Optional: Close the InfoWindow on mouseout
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });

        if (isMarkerClickable)
          marker.addListener("click", () => handleMarkerClick(location));
        markersRef.current.push(marker);
      });
    }, [locations, mapLoaded, isLoaded]);

    useEffect(() => {
      if (enablePolygon && mapLoaded) {
        initializeDrawingManager(map);
      }
    }, [enablePolygon, mapLoaded]);

    useEffect(() => {}, [selectedLocations]);

    return isLoaded ? (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={locations[0] || { lat: 43.26900089999999, lng: -79.975833 }}
        zoom={12}
        onLoad={handleMapLoad}
      ></GoogleMap>
    ) : (
      <div>
        {loadError ? "Error loading Google Maps" : "Loading Google Maps..."}
      </div>
    );
  }
);

export default MapBox;
