import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";
import { useSelector } from "react-redux";
import TicketAPIs from "../../APIs/ticket";
import NotificationAPIs from "../../APIs/notifications/api.notifications";

const Heading = ({ text, btn, refresh }) => {
  const navigate = useNavigate();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const { department } = useSelector((state) => state.auth);

  const syncTickets = async () => {
    const data = await TicketAPIs.synchronizeTickets();
    if (data.data.success) {
      const ok = alert("Tickets synchronized successfully");
      if (ok) {
        window.location.reload();
      }
    }
  };

  const getNotificationsCount = async () => {
    const data = await NotificationAPIs.getUnSeenNotificationsCount();
    if (data.data.success) {
      return data.data.data;
    }
  };

  useEffect(() => {
    const fetchNotificationsCount = async () => {
      const data = await getNotificationsCount();
      setNotificationsCount(data);
    };

    fetchNotificationsCount();
  }, []);

  return (
    <div className={`${classes.heading} flex-box mb-3`}>
      <h2>{text}</h2>
      <div>
        {department?.name === "locator" &&
          window.location.href.includes("query-search") && (
            <Button
              onClick={() => {
                navigate("/dashboard/hours-log");
              }}
              // className={classes.iconBtn}
              style={{ marginRight: "16px" }}
            >
              Add Hour Log
            </Button>
          )}

        {department?.name === "admin" &&
          window.location.href.includes("query-search") && (
            <Button
              onClick={async () => {
                const sure = window.confirm(
                  "Are you sure you want to sync tickets?"
                );
                if (sure) {
                  await syncTickets();
                }
              }}
              // className={classes.iconBtn}
              style={{ marginRight: "16px" }}
            >
              Sync Tickets
            </Button>
          )}

        {btn && (
          <Button
            onClick={() => {
              navigate("/dashboard/alert-messages");
            }}
            className={classes.iconBtn}
          >
            <span
              style={{
                fontSize: "13px",
              }}
            >
              {notificationsCount > 0 ? notificationsCount : 0}
            </span>
            Unread Notifications
          </Button>
        )}
      </div>

      {refresh && (
        <Button
          onClick={() => {
            window.location.reload();
          }}
          className={`exclude-print ${classes.iconBtn}`}
        >
          <FiRefreshCcw /> Refresh
        </Button>
      )}
    </div>
  );
};

export default Heading;
