import API from "APIs/base";
import { ENDPOINT } from "config/constants";

// get all notifications
const getNotifications = async () => {
  return await API.getMethod(ENDPOINT.notifications.allNotifications, true);
};

const markAsRead = async (id) => {
  return await API.putMethod(
    `${ENDPOINT.notifications.markAsRead}/${id}`,
    true
  );
};

const getUnSeenNotificationsCount = async () => {
  return await API.getMethod(
    ENDPOINT.notifications.unSeenNotificationsCount,
    true
  );
};

export default {
  getNotifications,
  markAsRead,
  getUnSeenNotificationsCount,
};
