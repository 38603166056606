import Heading from "Components/Heading";
import Notification from "./notification";
import NotificationApi from "../../APIs/notifications/api.notifications";
import { useEffect, useState } from "react";
import sseService from "../../Utils/SSEvents";

const UpdatesNotifications = () => {
  const [notificationsData, setNotificationsData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchNotifications = async () => {
    try {
      const response = await NotificationApi.getNotifications();
      if (response?.data?.success) {
        setNotificationsData(response.data.data);
      }
    } catch (error) {
      console.log("Error fetching notifications:", error);
    }
  };
  const markNotificationAsRead = async (id) => {
    try {
      const notification = notificationsData.find((n) => n.id === id);
      if (notification?.isSeen) {
        return;
      }

      // 🔥 Call API to mark as read
      const response = await NotificationApi.markAsRead(id);

      if (response?.data?.success) {
        setNotificationsData((prevNotifications) =>
          prevNotifications.map((n) =>
            n.id === id ? { ...n, isSeen: true } : n
          )
        );
      }
    } catch (error) {
      console.log("Error marking notification as seen:", error);
    }
  };

  useEffect(() => {
    sseService.on("notifications", (event) => {
      const data = JSON.parse(event?.data);
      setNotificationsData(data?.notifications);
    });

    const user = JSON.parse(localStorage.getItem("user"));
    const clientId = user?.department?.name === "admin" ? user?.id : null;
    if (clientId) {
      setIsAdmin(true);
    }

    fetchNotifications();
  }, []);

  return (
    <>
      {isAdmin && (
        <section>
          <Heading text={"Updates"} />

          <Notification
            notifications={notificationsData}
            markAsRead={markNotificationAsRead}
          />
        </section>
      )}
      {!isAdmin && (
        <h4
          style={{
            margin: "2rem",
          }}
        >
          Forbidden Resource - 403
        </h4>
      )}
    </>
  );
};

export default UpdatesNotifications;
