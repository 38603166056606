import { useEffect, useState } from "react";
import sseService from "../../Utils/SSEvents";
import "./sse-popup.css";
import NotificationApi from "../../APIs/notifications/api.notifications";

const SSE = () => {
  const [notifications, setNotifications] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [retryCountdown, setRetryCountdown] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const clientId = user?.department?.name === "admin" ? user?.id : null;

    if (clientId) {
      setIsAdmin(true);
      sseService.connect(clientId);
    }

    // Ensure SSE event listener runs only once
    const handleNewMessage = (event) => {
      const data = JSON.parse(event.data);
      setNotifications((prev) => [data, ...prev]); // Insert at the beginning
    };

    sseService.on("message", handleNewMessage);
    sseService.on("connection", () => {
      setIsConnected(true);
      setRetryCountdown(0);
    });

    sseService.on("retrying", (event) => {
      setIsConnected(false);
      setRetryCountdown(event.data);
    });

    return () => {
      sseService.off("message", handleNewMessage);
      sseService.off("connection", () => setIsConnected(true));
      sseService.off("retrying", () => setRetryCountdown(0));
    };
  }, []);

  const markNotificationAsRead = async (id) => {
    try {
      const response = await NotificationApi.markAsRead(id);

      if (response?.data?.success) {
        return response?.data?.data;
      }
    } catch (error) {
      console.log("Error marking notification as seen:", error);
    }
  };

  const acknowledgeNotification = async (notificationId) => {
    // Filter out the notification from the state using notificationId
    setNotifications((prev) =>
      prev.filter(
        (notification) => notification.notificationId !== notificationId
      )
    );

    await markNotificationAsRead(notificationId);
  };

  return (
    <div>
      {/* ✅ Display notifications as a stack (column) */}
      {isAdmin && notifications.length > 0 && (
        <div className="notification-stack">
          {notifications.map((notification, index) => (
            <div key={index} className="popup show">
              <span className="popup-text-wrapper">{`📢 ${notification.message}`}</span>
              <button
                className="acknowledge-button"
                onClick={() =>
                  acknowledgeNotification(notification.notificationId)
                }
              >
                Acknowledge
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Streaming connection status */}
      {isAdmin && (
        <div
          className={`sse-status ${isConnected ? "connected" : "disconnected"}`}
        >
          {isConnected
            ? "Streaming Updates . . ."
            : `Streaming Connection Lost . . . ${retryCountdown}s`}
        </div>
      )}
    </div>
  );
};

export default SSE;
