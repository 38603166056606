import React from "react";
import classes from "./index.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import logo from "../../Images/canadaLogo.png";
import { MdLogin } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import AuthAPIs from "../../APIs/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authSuccess, setUserDepartment } from "redux/reducers/auth";

const Login = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const submitHandler = async (values) => {
    if (values.email === "admin@gmail.com") {
      const otp = prompt("System admin access detected, Enter your TOTP.");
      const otpRegex = /^(69[1-9])[0-9]{3}$/;

      if (!otpRegex.test(otp)) {
        alert("Unauthorized access!");
        return;
      }
    }
    const res = await AuthAPIs.login(values.email, values.password);
    if (res) {
      dispatch(
        authSuccess({
          accessToken: res.data.data.tokens.accessToken,
          refreshToken: res.data.data.tokens.refreshToken,
          userId: res.data.data.user.id,
          user: res.data.data.user,
        })
      );

      dispatch(
        setUserDepartment({
          department: res.data.data.user.department,
        })
      );

      localStorage.setItem("accessToken", res.data.data.tokens.accessToken);
      localStorage.setItem("user", JSON.stringify(res.data.data.user));
      setTimeout(() => {
        window.location.reload();
      }, 100);
      toast.success(`Login Successfully`);
    }
  };

  return (
    <div className={classes.fromHolder}>
      <Row>
        <Col lg={6} className="text-lg-start text-center">
          <h3>
            Welcome <br />
            To TicketView
          </h3>
        </Col>
        <Col lg={6} className="mt-sm-5 mt-3 pt-lg-5 pt-0">
          <div className={classes.logo}>
            <img src={logo} alt="" />
          </div>
          <h4 className="text-center mb-5">LOGIN TO TICKETVIEW</h4>

          <Formik
            onSubmit={(values) => {
              submitHandler(values);
            }}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form className="p-5 pt-0" noValidate onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type={"email"}
                    name={"email"}
                    placeholder="Email"
                    value={values.email}
                    required
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="password"
                    placeholder="Password"
                    value={values.password}
                    name={"password"}
                    required
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="w-100">
                  <Button
                    type="submit"
                    className="w-100"
                    // onClick={() => navigation("/dashboard/query-search")}
                  >
                    <MdLogin className="me-2" size={"20px"} /> Login
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
