import React from "react";
import PropTypes from "prop-types";
import "./notification.css"; // Import styles
// import all these icons locally
import EmergencyTicketLogo from "../../Images/notifications/emergencyticketlogo.png";
import PreCompleteTicketLogo from "../../Images/notifications/precompleteticketlogo.png";
import SystemHealthLogo from "../../Images/notifications/systemhealthlogo.png";
import InfoLogo from "../../Images/notifications/infologo.png";
import CancelLogo from "../../Images/notifications/cancellogo.png";
import { API_URL } from "config/constants";

// Map notification type to an image
const notificationIcons = {
  // EmergencyTicketLogo: "https://cdn3.emoji.gg/emojis/73011-ticket.png",
  // PreCompleteTicketLogo:
  //   "https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/213584/dark2---rounded.png",
  // SystemHealthLogo: "https://static.thenounproject.com/png/2426100-200.png",
  // InfoLogo:
  //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr0VPjHKMfaLdytgMklecVqwbXhjTBBKTEaQ&s",
  // CancelLogo:
  //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp3J1Vmtnok5k2iM6im6xLkRidsoVq8cjbnA&s",

  EmergencyTicketLogo: EmergencyTicketLogo,
  PreCompleteTicketLogo: PreCompleteTicketLogo,
  SystemHealthLogo: SystemHealthLogo,
  InfoLogo: InfoLogo,
  CancelLogo: CancelLogo,
};

const Notification = ({ notifications, markAsRead }) => {
  const formatDateTime = (timestamp) => {
    const dateObj = new Date(timestamp);
    return dateObj.toLocaleString("en-CA", {
      timeZone: "America/Toronto",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  // create a function that will pass id to the parent component
  const handleClick = (id) => {
    markAsRead(id);
  };

  return (
    <div className="notification-container">
      {notifications.map(
        ({ id, type, title, message, isSeen, createdAt, buttonText }) => {
          // Find the correct icon based on `type`
          let iconSrc = notificationIcons["InfoLogo"]; // Default icon
          if (type.toLowerCase().includes("emergency"))
            iconSrc = notificationIcons["EmergencyTicketLogo"];
          else if (type.toLowerCase().includes("pre complete"))
            iconSrc = notificationIcons["PreCompleteTicketLogo"];
          else if (type.toLowerCase().includes("system health"))
            iconSrc = notificationIcons["SystemHealthLogo"];
          else if (type.toLowerCase().includes("info"))
            iconSrc = notificationIcons["InfoLogo"];
          else if (type.toLowerCase().includes("cancel"))
            iconSrc = notificationIcons["CancelLogo"];

          return (
            <div
              key={id}
              className={`notification ${type
                .toLowerCase()
                .replace(/\s+/g, "-")}`}
              onClick={() => {
                handleClick(id);

                const ticketNumber = title.match(/\b\d{6,}\b/);

                // ✅ If there's a ticket number, open the link in a new tab
                if (ticketNumber) {
                  window.open(
                    `/dashboard/ticket-detail/${ticketNumber}`,
                    "_blank"
                  );
                } else if (type.toLowerCase().includes("system health")) {
                  window.open("/life");
                }
              }}
            >
              <div className="image-container">
                <img src={iconSrc} alt={type} className="notification-image" />
                {!isSeen && <span className="unseen-dot"></span>}
              </div>
              <div className="notification-content">
                <strong className="notification-title">{title}</strong>
                <p className="notification-message">{message}</p>
              </div>
              <div className="notification-actions">
                {/* {isSeen && (
                  <button
                    className="notification-button"
                    onClick={() => {
                      console.log("Acknowledge button clicked");
                    }}
                  >
                    Acknowledge
                  </button>
                )} */}
                <p className="notification-timestamp">
                  {formatDateTime(createdAt)}
                </p>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

Notification.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      isSeen: PropTypes.bool.isRequired,
      buttonText: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Notification;
