import React from "react";
import classes from "./index.module.scss";
import Sidebar from "Components/SideNav";
import Header from "Components/Header";
import { useLocation } from "react-router-dom";
import SSE from "Components/SSE/sse-popup";

const MainLayout = ({ children }) => {
  const location = useLocation();
  const hideSidebar = location.pathname.includes("dashboard/ticket-detail");

  return (
    <div id={classes.wrapper}>
      {!hideSidebar && <Sidebar />}
      <div className={`${classes.content} ${hideSidebar && "ms-0"}`}>
        <Header />
        {!hideSidebar && <SSE />}

        <main id={classes.main}>{children}</main>
      </div>
    </div>
  );
};

export default MainLayout;
