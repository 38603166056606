import Heading from "Components/Heading";
import Loader from "Components/Loader";
import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "Helper/Converters";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import classes from "./index.module.scss";
import AccordionsSection from "Components/Accordians";
import { MdOutlineEmail } from "react-icons/md";
import TicketAPIs from "../../APIs/ticket";
import MapBox from "Components/MapBox";
import DocumentModal from "Components/DocumentModal";
import LogModal from "Components/LogModal";
import TicketOnSameStreet from "Components/TicketOnSameRoadModal";
import { toast } from "react-toastify";
import CallLogModal from "./components/CallLogModal";
import PreCompleteModal from "./components/PreCompleteModal";
import { FaFileAlt } from "react-icons/fa";
import Slider from "react-slick";
import { ReactComponent as FolderIcon } from "../../Images/folder.svg";
import { ReactComponent as FolderUserIcon } from "../../Images/folder-user.svg";
import { priorityColors } from "Utils/utils";

const TicketDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ticketInfo, setTicketInfo] = useState(null);
  const [ticketID, setTicketID] = useState(null);
  const [ticketFiles, setTicketFiles] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [unCompleteModalShow, setUnCompleteModalShow] = useState(false);
  const [logModalShow, setLogModalShow] = useState(false);
  const [calllogModalShow, setCallLogModalShow] = useState(false);
  const [preCompletedModalShow, setPreCompletedModalShow] = useState(false);
  const [ticketModalShow, setTicketModalShow] = useState(false);
  const [user, setUser] = useState();
  const handleTicketLog = () => {
    setLogModalShow(true);
  };

  const handleOfficeClear = async () => {
    if (ticketID) {
      await TicketAPIs.officeClear({
        ticketId: [ticketID],
      });

      toast.success("Ticket send to office clear successfully!");
    } else {
      toast.error("Ticket not send to office clear successfully!");
    }
  };

  const handleUnCompleteTicket = async () => {
    setUnCompleteModalShow(true);
  };
  const handleCompleteTicket = async () => {
    if (ticketID) {
      if (user?.department.name === "admin") {
        if (ticketInfo.preCompletedDate) {
          await TicketAPIs.completeTicket({
            ticketId: [ticketID],
            userId: user?.id,
            isOfficeClear: false,
          });
          toast.success("Ticket completed successfully!");
        } else {
          toast.error("Ticket is not pre-completed by locator");
        }
      } else {
        setPreCompletedModalShow(true);
      }
    } else {
      toast.error("Ticket not completed successfully!");
    }
  };
  const handleCancelTicket = async () => {
    if (window.confirm("Are you sure, you want to cancel the ticket?")) {
      if (ticketID) {
        await TicketAPIs.cancelTicket({
          ticketId: [ticketID],
          userId: user?.id,
        });

        toast.success("Ticket cancel successfully!");
        window.location.reload();
      } else {
        toast.error("Ticket not canceled successfully!");
      }
    } else {
      // User clicked 'Cancel', do nothing
    }
  };

  const handleReinstate = async () => {
    if (window.confirm("Are you sure, you want to un-cancel this ticket?")) {
      if (ticketID) {
        await TicketAPIs.reinstateTicket({
          ticketId: [ticketID],
          userId: user?.id,
        });

        toast.success("Ticket reinstated successfully!");
        window.location.reload();
      } else {
        toast.error("Ticket not reinstated successfully!");
      }
    } else {
      // User clicked 'Cancel', do nothing
    }
  };
  const handleSameStreetTicket = () => {
    setTicketModalShow(true);
  };
  const printHandler = () => {
    window.print();
  };

  const revokeTicket = async () => {
    const res = await TicketAPIs.revokeTicket({
      ticketId: [ticketID],
    });
    if (res) {
      toast.success("Tickets Revoked Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const data = [
    { name: "Ticket Log", onClick: handleTicketLog },
    {
      name: "One Call File",
      onClick: () => {
        setCallLogModalShow(true);
      },
    },
    { name: "Print Ticket", onClick: printHandler },
    // { name: "Upload Document", onClick: handleTicket },
    { name: "Ticket On Same Street", onClick: handleSameStreetTicket },
    // { name: "Fix Map", onClick: handleTicket },
    {
      name: "Email Documents",
      onClick: () => {
        setModalShow(true);
      },
    },

    // we have to condationally show this button if cancel date is null then show this button
    ...(user?.department.name === "admin" &&
    ticketInfo?.preCompletedDate &&
    ticketInfo?.detail?.preCompletedBy
      ? [
          {
            name: "Complete Ticket",
            onClick: async () => {
              if (
                window.confirm("Are you sure, you want to complete the ticket")
              ) {
                await handleCompleteTicket();
              }
            },
          },
        ]
      : []),

    // same we are doing for locator
    ...(user?.department.name === "locator"
      ? [{ name: "Pre-Complete Ticket", onClick: handleCompleteTicket }]
      : []),

    ...(!ticketInfo?.detail?.cancelDate
      ? [{ name: "Cancel Ticket", onClick: handleCancelTicket }]
      : []),
    // { name: "Reinstate", onClick: handleReinstate },
    // we have to condationally show this button if cancel date is not null then show this button
    ...(ticketInfo?.detail?.cancelDate
      ? [{ name: "Re Instate Ticket", onClick: handleReinstate }]
      : []),
    { name: "Add to Office Clear", onClick: handleOfficeClear },
    { name: "Uncomplete Ticket", onClick: handleUnCompleteTicket },
    { name: "Revoke Ticket", onClick: revokeTicket },
    {
      name: "Fix Compliance Date",
      onClick: async () => {
        const ticketNumber = ticketInfo?.detail?.ticketNo;
        if (
          window.confirm(
            `Are you sure you want to fix compliance date for ticket ${ticketNumber}?`
          )
        ) {
          if (ticketNumber) {
            const res = await TicketAPIs.fixComplianceDate({
              data: { ticketNumber },
            });
            if (res.data.success) {
              window.alert("Compliance Date Fixed Successfully");
              window.location.reload();
            }
          }
        }
      },
    },
  ];

  const getTicketInfo = async () => {
    setIsLoading(true);
    const tickets = await TicketAPIs.search({
      ticketNo: id,
      removeOr: true,
      showAll: true,
    });

    if (tickets) {
      setTicketInfo(tickets.data.data[0]);
      setTicketID(tickets?.data?.data[0]?.id);
    }
    setIsLoading(false);
  };

  const getTicketFiles = async () => {
    if (ticketInfo) {
      const tickets = await TicketAPIs.ticketFiles({
        ticketId: [ticketInfo?.detail?.id],
      });

      if (tickets) {
        setTicketFiles(tickets.data.data);
      }
    }
  };

  useEffect(() => {
    getTicketInfo();
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    getTicketFiles();
  }, []);

  useEffect(() => {
    getTicketFiles();
  }, [ticketInfo]);

  const sliderSettings = {
    dots: false,
    speed: 500,
    infinite: false,
    arrows: true,
    slidesToShow: 15,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const fileData = [
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
    {
      icon: <FaFileAlt />,
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Ticket Detail"} refresh />
          <Card>
            {/* <p className="mb-3">
              Ontario PEEL York Rural routine ({ticketInfo?.detail?.ticketType})
            </p> */}
            <Row className="mb-3">
              <Col lg={4}>
                <div className={classes.flexBox}>
                  <div>
                    <p>Ticket Number:</p>
                    {ticketInfo?.detail?.cancelDate && (
                      <span className="text-danger fw-bold">Cancelled</span>
                    )}
                  </div>
                  <span>{ticketInfo?.detail?.ticketNo}</span>
                </div>
                <div className={classes.flexBox}>
                  <p>Reference Ticket:</p>
                  <span>
                    {ticketInfo?.detail?.referenceTicketNo
                      ? ticketInfo?.detail?.referenceTicketNo
                      : "-"}
                  </span>
                </div>
                <div className={classes.flexBox}>
                  <p>WAP No:</p>
                  <span>
                    {ticketInfo?.detail?.wapNo
                      ? ticketInfo?.detail?.wapNo
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg={4}>
                <div className={classes.flexBox}>
                  <p>Ticket Type / Priority:</p>
                  <span>
                    {ticketInfo?.detail?.ticketType || "-"} /{" "}
                    {ticketInfo?.detail?.priority || "-"}
                  </span>
                </div>
                <div className={classes.flexBox}>
                  <p>Work to Begin Date:</p>
                  <span>
                    {ticketInfo?.workToBeginDate
                      ? `${ticketInfo?.workToBeginDate} `
                      : "-"}
                  </span>
                </div>
                <div className={classes.flexBox}>
                  <p>Renegotiated Date:</p>
                  <span>
                    {ticketInfo?.rescheduleDate
                      ? ticketInfo?.rescheduleDate
                      : "-"}
                  </span>
                </div>

                <div className={classes.flexBox}>
                  <p>Original Compliance Date:</p>
                  <span>
                    {ticketInfo?.originalComplianceDate
                      ? ticketInfo?.originalComplianceDate
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg={4}></Col>
            </Row>

            <Row>
              <Col lg={7}>
                <h6 className={`text-black pb-2 mb-2 ${classes.heading}`}>
                  Dig Location
                </h6>
                <Row className="mb-3">
                  <Col xl={6}>
                    <div className={classes.flexBox}>
                      <p>Address:</p>
                      <span>
                        {ticketInfo?.detail?.digAddress
                          ? ticketInfo?.detail?.digAddress
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Street:</p>
                      <span>
                        {(ticketInfo?.detail?.ticketNo.includes("PVT")
                          ? ticketInfo.detail.digAddress?.split(",")[2]
                          : ticketInfo?.detail?.digStreet) || "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>City:</p>
                      <span>
                        {ticketInfo?.detail?.digCity
                          ? ticketInfo?.detail?.digCity
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Community:</p>
                      <span>
                        {ticketInfo?.detail?.digCommunity
                          ? ticketInfo?.detail?.digCommunity
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Region:</p>
                      <span>
                        {ticketInfo?.detail?.region
                          ? ticketInfo?.detail?.region
                          : "-"}
                      </span>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className={classes.flexBox}>
                      <p>Nearest Interaction:</p>
                      <span>
                        {ticketInfo?.detail?.digNearestIntersection
                          ? ticketInfo?.detail?.digNearestIntersection
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>No of Segments:</p>
                      <span>
                        {ticketInfo?.detail?.digSegments
                          ? ticketInfo?.detail?.digSegments
                          : 0}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Depth:</p>
                      <span>
                        {ticketInfo?.detail?.digDepth
                          ? ticketInfo?.detail?.digDepth
                          : 0}
                      </span>
                    </div>
                  </Col>
                </Row>
                <h6 className={`text-black pb-2 mb-2 ${classes.heading}`}>
                  Caller Information
                </h6>
                <Row className="mb-3">
                  <Col xl={6}>
                    <div className={classes.flexBox}>
                      <p>Company:</p>
                      <span>
                        {ticketInfo?.detail?.company_name
                          ? ticketInfo?.detail?.company_name
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Contractor:</p>
                      <span>
                        {ticketInfo?.detail?.contractorName
                          ? ticketInfo?.detail?.contractorName
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p> Contact :</p>
                      <span>
                        {ticketInfo?.detail?.primaryContact
                          ? ticketInfo?.detail?.primaryContact
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p> Phone :</p>
                      <span>
                        {ticketInfo?.detail?.phone
                          ? ticketInfo?.detail?.phone
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Alt. Contact:</p>
                      <span>
                        {ticketInfo?.detail?.alternateContact
                          ? ticketInfo?.detail?.alternateContact
                          : "-"}
                      </span>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className={classes.flexBox}>
                      <p>Alt. Phone:</p>
                      <span>
                        {ticketInfo?.detail?.alternatePhone
                          ? ticketInfo?.detail?.alternatePhone
                          : ticketInfo?.detail?.onSiteContactNumber
                          ? ticketInfo?.detail?.onSiteContactNumber
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p> Email :</p>
                      <span
                        style={{
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                      >
                        {ticketInfo?.detail?.email
                          ? ticketInfo?.detail?.email
                          : "-"}
                      </span>
                    </div>
                  </Col>
                </Row>

                <h6 className={`text-black pb-2 mb-2 ${classes.heading}`}>
                  Other Information
                </h6>
                <Row className="">
                  <Col lg={12}>
                    <div className={classes.flexBox}>
                      <p>Additional Information:</p>
                      <span>
                        {ticketInfo?.detail?.additionalInfo
                          ? ticketInfo?.detail?.additionalInfo
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Type Of Work:</p>
                      <span>
                        {ticketInfo?.detail?.typeOfWork
                          ? ticketInfo?.detail?.typeOfWork
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Type Of Property:</p>
                      <span>
                        {ticketInfo?.detail?.reasonForWork
                          ? ticketInfo?.detail?.reasonForWork
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Work Being Done For:</p>
                      <span>
                        {ticketInfo?.detail?.workDoneFor
                          ? ticketInfo?.detail?.workDoneFor
                          : "-"}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={5} className="mt-4">
                <div className="align-items-center mb-3">
                  <Col className="mb-3">
                    <Slider {...sliderSettings} className={"file-slider"}>
                      {ticketFiles
                        .sort(
                          (a, b) =>
                            new Date(a.createdAt) - new Date(b.createdAt)
                        ) // Sort by createdAt, latest first
                        .map((item, ind) => {
                          return (
                            <div className="file-slider">
                              <a href={`${item?.presignedUrl}`} target="_blank">
                                {item?.userId ===
                                  "9484cedd-d085-4ecd-afc2-b86c2ec590c7" ||
                                item?.userId ===
                                  "95699a91-f564-4800-adc7-8d34a1c80f64" ||
                                item?.userId === null ? (
                                  <FolderIcon width={32} />
                                ) : (
                                  <FolderUserIcon width={32} />
                                )}
                              </a>
                            </div>
                          );
                        })}
                    </Slider>
                  </Col>
                  <Col>
                    <Button
                      className="d-flex align-items-center "
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      <MdOutlineEmail size={18} />
                      Email Documents
                    </Button>
                  </Col>
                </div>

                <div className="h-75">
                  <MapBox
                    locations={[
                      {
                        id: 0,
                        lat: parseFloat(ticketInfo?.detail?.locationLat),
                        lng: parseFloat(ticketInfo?.detail?.locationLng),
                        label: `
                        <b>Ticket No:</b> ${
                          ticketInfo?.detail?.ticketNo || ""
                        }<br>
                        <b>Contractor Name:</b> ${
                          ticketInfo?.detail?.contractorName ||
                          ticketInfo?.detail?.contractor_name ||
                          ""
                        }<br>
                        <b>Company Name:</b> ${
                          ticketInfo?.detail?.companyName ||
                          ticketInfo?.detail?.company_name ||
                          ""
                        }<br>
                        <b>Ticket Type:</b> ${
                          ticketInfo?.detail?.ticketType || ""
                        }<br>
                        <b>Dig Address:</b> ${
                          ticketInfo?.detail?.digAddress || ""
                        }`,
                        color: priorityColors[ticketInfo?.detail?.priority],
                      },
                    ]}
                    isMarkerClickable={false}
                    enablePolygon={false}
                    enableTicketDetail={true}
                    isDetailComponent={true}
                    mapContainerStyle={{
                      height: "420px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={4}>
                    <div className={classes.flexBox}>
                      <p>Station Codes:</p>
                      <span>
                        {ticketInfo?.detail?.stationCodes
                          ? ticketInfo?.detail?.stationCodes
                              .map((obj) => obj.name)
                              .join(", ")
                          : "-"}
                      </span>
                    </div>

                    <div className={classes.flexBox}>
                      <p>Transmit Date:</p>
                      <span>
                        {ticketInfo?.transmitDate
                          ? `${ticketInfo?.transmitDate} `
                          : "-"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Site Meeting:</p>
                      <span>
                        {ticketInfo?.detail?.siteMeeting
                          ? ticketInfo?.detail?.siteMeeting
                          : "No"}
                      </span>
                    </div>

                    <div className={classes.flexBox}>
                      <p>Permit:</p>
                      <span>
                        {ticketInfo?.detail?.permit
                          ? ticketInfo?.detail?.permit
                          : "No"}
                      </span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={classes.flexBox}>
                      <p> Latitude :</p>
                      <span>
                        {ticketInfo?.detail?.locationLat
                          ? ticketInfo?.detail?.locationLat
                          : "0.0"}
                      </span>
                    </div>
                    <div className={classes.flexBox}>
                      <p>Original Call Date:</p>
                      <span>
                        {ticketInfo?.startDate
                          ? `${ticketInfo?.startDate} `
                          : "-"}
                      </span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={classes.flexBox}>
                      <p>Longitude:</p>
                      <span>
                        {ticketInfo?.detail?.locationLng
                          ? ticketInfo?.detail?.locationLng
                          : "0.0"}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <h6 className={`text-black pb-0 mb-4 ${classes.heading}`}></h6>
              <Row className="mb-3">
                <Col lg={4}>
                  <div className={classes.flexBox}>
                    <p>Entered On:</p>
                    <span>
                      {ticketInfo?.createdAt
                        ? `${ticketInfo?.createdAt} `
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Assigned To:</p>
                    <span>
                      {ticketInfo?.detail?.assignTo?.firstName
                        ? ticketInfo?.detail?.assignTo.firstName
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Pre Completed On:</p>
                    <span>
                      {ticketInfo?.preCompletedDate
                        ? `${ticketInfo?.preCompletedDate} `
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Completed By:</p>
                    <span>
                      {ticketInfo?.detail?.completedBy?.firstName
                        ? ticketInfo?.detail?.completedBy?.firstName
                        : "-"}
                    </span>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={classes.flexBox}>
                    <p>Entered By:</p>
                    <span>
                      {ticketInfo?.detail?.enteredBy
                        ? ticketInfo?.detail?.enteredBy?.firstName
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Reassigned To:</p>
                    <span>
                      {ticketInfo?.detail?.reAssignTo?.firstName
                        ? ticketInfo?.detail?.reAssignTo?.firstName
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Reassigned Date:</p>
                    <span>
                      {ticketInfo?.reassingndate
                        ? ticketInfo?.reassingndate
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Pre Completed By:</p>
                    <span>
                      {ticketInfo?.detail?.preCompletedBy?.firstName
                        ? ticketInfo?.detail?.preCompletedBy?.firstName
                        : "-"}
                    </span>
                  </div>

                  <div className={classes.flexBox}>
                    <p>Completed On:</p>
                    <span>
                      {ticketInfo?.completedDate
                        ? `${ticketInfo?.completedDate} `
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Cancelled By:</p>
                    <span>
                      {ticketInfo?.detail?.canceledBy?.firstName
                        ? ticketInfo?.detail?.canceledBy?.firstName
                        : "-"}
                    </span>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={classes.flexBox}>
                    <p>Assigned Date:</p>
                    <span>
                      {ticketInfo?.assign ? `${ticketInfo?.assign} ` : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Assigned By:</p>
                    <span>
                      {ticketInfo?.detail?.assignBy?.firstName
                        ? ticketInfo?.detail?.assignBy?.firstName
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Start Date:</p>
                    <span>
                      {ticketInfo?.startDate
                        ? `${ticketInfo?.startDate} `
                        : "-"}
                    </span>
                  </div>
                  <div className={classes.flexBox}>
                    <p>Cancelled Date:</p>
                    <span>
                      {ticketInfo?.cancelDate
                        ? `${ticketInfo?.cancelDate} `
                        : "-"}
                    </span>
                  </div>
                </Col>
              </Row>
            </Row>

            <AccordionsSection ticketData={ticketInfo} key={ticketInfo} />
            <ul className="btn-list exclude-print">
              {data.map((item, ind) => (
                <li key={ind}>
                  <Button variant="outline-primary" onClick={item.onClick}>
                    {item.name}
                  </Button>
                </li>
              ))}
            </ul>
          </Card>
        </section>
      )}
      <DocumentModal
        to={ticketInfo?.detail?.email}
        cc={
          ticketInfo?.code[0]?.name === "AURORA01"
            ? "auroralocates@aurora.ca"
            : ""
        }
        subject={[ticketInfo?.detail?.ticketNo]}
        body={`Hi ${ticketInfo?.detail?.contractorName} , 

          Please see attached Document 

        ${
          ticketInfo?.detail?.isOfficeClear
            ? "Based on your area of request, we have confirmed that the Brampton utility is cleared within the following located limits: "
            : ""
        }Ticket Number: ${ticketInfo?.detail?.ticketNo}



        Thanks
        TransCanada Utilities Inc.
        Email: locates@transcanadautilities.com
        Tel: 1-888-647-5650`}
        show={modalShow}
        ticketIds={[ticketInfo?.detail?.id]}
        userId={user?.id}
        onHide={() => setModalShow(false)}
      />

      {/* Uncomplete Ticket Email Modal */}
      {/* Uncomplete Ticket Email Modal Below */}
      <DocumentModal
        to={
          ticketInfo?.reassigned?.email
            ? ticketInfo?.reassigned?.email
            : ticketInfo?.assigntoo?.email
        }
        // cc={ticketInfo?.detail?.ccEmail}
        subject={`Uncompleted Ticket # - ${ticketInfo?.detail?.ticketNo}`}
        body={`Hi ${
          ticketInfo?.assigntoo
            ? ticketInfo?.assigntoo.username
            : ticketInfo?.reassigned?.username
        },
          Ticket Number: ${ticketInfo?.detail?.ticketNo} has been uncompleted.

          Thanks
          TransCanada Utilities Inc.
          Email: locates@transcanadautilities.com
          Tel: 1-888-647-5650`}
        show={unCompleteModalShow}
        ticketIds={[ticketInfo?.detail?.id]}
        userId={user?.id}
        onHide={() => setUnCompleteModalShow(false)}
        ticketId={[ticketID]} // Fix ticketId reference here
        unCompleteModal={true}
      />
      <CallLogModal
        showModal={calllogModalShow}
        setShowModal={setCallLogModalShow}
        logData={ticketInfo?.detail?.callLog}
      />
      <LogModal
        id={ticketInfo?.detail?.id}
        show={logModalShow}
        onHide={() => setLogModalShow(false)}
      />
      <TicketOnSameStreet
        ticketData={ticketInfo}
        show={ticketModalShow}
        onHide={() => setTicketModalShow(false)}
      />
      <PreCompleteModal
        showModal={preCompletedModalShow}
        handleClose={() => setPreCompletedModalShow(false)}
        ticketId={ticketInfo?.detail?.id}
        userId={user?.id}
      />
    </>
  );
};

export default TicketDetails;

// return (
//   <div className="file-slider">
//     <div
//       onClick={async (e) => {
//         e.preventDefault();
//         const response = await fetch(
//           item?.presignedUrl
//         );
//         const blob = await response.blob();
//         const url = window.URL.createObjectURL(blob);
//         const link = document.createElement("a");
//         link.href = url;
//         link.download = item?.filename || "download"; // Set default filename if needed
//         document.body.appendChild(link);
//         link.click();
//         // Clean up
//         document.body.removeChild(link);
//         window.URL.revokeObjectURL(url);
//       }}
//       style={{ cursor: "pointer" }}
//     >
//       {item?.userId ===
//         "9484cedd-d085-4ecd-afc2-b86c2ec590c7" ||
//       item?.userId ===
//         "95699a91-f564-4800-adc7-8d34a1c80f64" ||
//       item?.userId === null ? (
//         <FolderIcon width={32} />
//       ) : (
//         <FolderUserIcon width={32} />
//       )}
//     </div>
//   </div>
// );
