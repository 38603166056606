import NotFound from "Pages/NotFound/loadable";
import MainLayout from "Layout/MainLayout";
import Login from "Pages/Login";
import AuthLayout from "Layout/AuthLayout";
import SearchTicket from "Pages/SearchTicket";
import ResignTicket from "Pages/ResignTickets";
import CreateNewTicket from "Pages/CreateNewTicket";
import NonComplaintReport from "Pages/NonComplaintReport";
import Billing from "Pages/Billing";
import Payroll from "Pages/PayrollAudit";
import HourLogs from "Pages/HoursLog";
import OfficeClear from "Pages/OfficeClear";
import DispatchTicket from "Pages/DispatchTicket";
import AssignTIckets from "Pages/AssignTickets";
import CloseOustannding from "Pages/CloseOurStanding";
import TicketByUser from "Pages/TicketByUser";
import UpdatesNotifications from "Pages/AlertMessage";
import ManageUser from "Pages/UserSetup";
import AddUser from "Pages/AddUser";
import EditUser from "Pages/EditUser";
import DepartmentPermisiion from "Pages/DeparmentPermission";
import Blocked from "Components/Blocked";
import TicketDetails from "Pages/TicketDetails";
import City from "Pages/City";
import StationCode from "Pages/StationCode";
import DocType from "Pages/DocType";
import Reason from "Pages/Reason";
import TicketTypes from "Pages/TicketTypes";
import Department from "Pages/Department";
import CreateNewTicketOntarioOne from "Pages/CreateNewTicket/OntarioOne";
import CreateNewTicketLocal from "Pages/CreateNewTicket/Local";
import CloseTickets from "Pages/CloseTicket";
import OutstandingTickets from "Pages/OutstandingTickets";
import ReassignByMap from "Pages/ReassignByMap";
import RescheduleRevoke from "Pages/RescheduleRevoke";
import AdminDashboard from "Pages/AdminDashboard";
import Region from "Pages/Region";
import SystemHealthDashboard from "Components/HealthChecks/health";
import ON1NonComplaintReport from "Pages/NonComplaintReport/on1ComplianceReport";

const routes = [
  {
    path: "/",
    authenticated: false,
    layout: AuthLayout,
    component: Login,
  },
  {
    path: "/life",
    authenticated: false,
    // layout: AuthLayout,
    component: SystemHealthDashboard,
  },
  {
    path: "/admin-dashboard",
    authenticated: true,
    layout: MainLayout,
    component: AdminDashboard,
  },
  {
    path: "/dashboard/query-search",
    authenticated: true,
    layout: MainLayout,
    component: SearchTicket,
  },
  {
    path: "/dashboard/reschedule-revoke",
    authenticated: true,
    layout: MainLayout,
    component: RescheduleRevoke,
  },
  {
    path: "/dashboard/query-search",
    authenticated: true,
    layout: MainLayout,
    component: ResignTicket,
  },
  // {
  //   path: "/dashboard/create-new-tickets-ontario-one",
  //   authenticated: true,
  //   layout: MainLayout,
  //   component: CreateNewTicketOntarioOne,
  // },
  {
    path: "/dashboard/create-new-tickets-local",
    authenticated: true,
    layout: MainLayout,
    component: CreateNewTicketLocal,
  },
  {
    path: "/dashboard/non-compliance-report",
    authenticated: true,
    layout: MainLayout,
    component: NonComplaintReport,
  },
  {
    path: "/dashboard/on1-non-compliance-report",
    authenticated: true,
    layout: MainLayout,
    component: ON1NonComplaintReport,
  },
  {
    path: "/dashboard/billing",
    authenticated: true,
    layout: MainLayout,
    component: Billing,
  },
  {
    path: "/dashboard/payroll-audit",
    authenticated: true,
    layout: MainLayout,
    component: Payroll,
  },
  {
    path: "/dashboard/hours-log",
    authenticated: true,
    layout: MainLayout,
    component: HourLogs,
  },
  {
    path: "/dashboard/office-clear",
    authenticated: true,
    layout: MainLayout,
    component: OfficeClear,
  },
  {
    path: "/dashboard/assign-tickets-by-map",
    authenticated: true,
    layout: MainLayout,
    component: DispatchTicket,
  },
  {
    path: "/dashboard/reassign-tickets-by-map",
    authenticated: true,
    layout: MainLayout,
    component: ReassignByMap,
  },
  {
    path: "/dashboard/assign-ticket",
    authenticated: true,
    layout: MainLayout,
    component: AssignTIckets,
  },
  {
    path: "/dashboard/reassign-ticket",
    authenticated: true,
    layout: MainLayout,
    component: ResignTicket,
  },
  {
    path: "/dashboard/close-tickets",
    authenticated: true,
    layout: MainLayout,
    component: CloseTickets,
  },
  {
    path: "/dashboard/outstanding-tickets",
    authenticated: true,
    layout: MainLayout,
    component: OutstandingTickets,
  },
  {
    path: "/dashboard/outstanding-tickets",
    authenticated: true,
    layout: MainLayout,
    component: TicketByUser,
  },
  {
    path: "/dashboard/alert-messages",
    authenticated: true,
    layout: MainLayout,
    component: UpdatesNotifications,
  },
  {
    path: "/dashboard/user-setup",
    authenticated: true,
    layout: MainLayout,
    component: ManageUser,
  },
  {
    path: "/dashboard/user-setup/add",
    authenticated: true,
    layout: MainLayout,
    component: AddUser,
  },
  {
    path: "/dashboard/user-setup/edit/:id",
    authenticated: true,
    layout: MainLayout,
    component: EditUser,
  },
  {
    path: "/dashboard/department-permission",
    authenticated: true,
    layout: MainLayout,
    component: DepartmentPermisiion,
  },
  {
    path: "/blockError",
    authenticated: true,
    layout: MainLayout,
    component: Blocked,
  },
  {
    path: "/dashboard/ticket-detail/:id",
    authenticated: true,
    layout: MainLayout,
    component: TicketDetails,
  },
  // {
  //   path: "/dashboard/city",
  //   authenticated: true,
  //   layout: MainLayout,
  //   component: City,
  // },
  {
    path: "/dashboard/doc-type",
    authenticated: true,
    layout: MainLayout,
    component: DocType,
  },
  {
    path: "/dashboard/reason",
    authenticated: true,
    layout: MainLayout,
    component: Reason,
  },
  {
    path: "/dashboard/region",
    authenticated: true,
    layout: MainLayout,
    component: Region,
  },
  {
    path: "/dashboard/station-codes",
    authenticated: true,
    layout: MainLayout,
    component: StationCode,
  },
  {
    path: "/dashboard/ticket-types",
    authenticated: true,
    layout: MainLayout,
    component: TicketTypes,
  },
  {
    path: "/dashboard/departments",
    authenticated: true,
    layout: MainLayout,
    component: Department,
  },

  { path: "*", component: NotFound, layout: MainLayout },
];

export default routes;
